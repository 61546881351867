<template>
  <b-row>
    <b-col cols="12">
      <h2>Visitas</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80" />

    <div class="col-12 mt-16">
      <datatable
        :items="items"
        :fields="fields"
        @changePerPage="perPageChanged"
      >
        <template slot="filters">
          <b-col
            cols="3"
            class="my-3"
          >
            <b-button
              v-b-modal.modal-add-document-admin
              variant="primary"
              class="btn-ghost"
            >
              Añadir nueva visita
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Seleccione departamento"
              label-for="filter-input"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              class="mb-0"
            >
              <b-row>
                <b-col cols="7">
                  <b-form-select
                    v-model="departament_filter"
                    size="sm"
                    :options="Departaments"
                    @change="onChangeDepartament"
                  />
                </b-col>
                <!-- <b-button
                  variant="primary"
                  class="btn-ghost mt-2"
                  size="sm"
                  @click="clearSelection"
                >
                  Limpiar
                </b-button> -->
              </b-row>
            </b-form-group>
          </b-col>
        </template>

        <template
          slot="actions"
          slot-scope="{ item }"
        >
          <b-row class="actions-icons">
            <span
              :id="'_show_button_' + item.uuid"
              title="ver"
              @click="show(item.uuid)"
            >
              <i class="iconly-Light-Show" />
            </span>
            <!-- <span title="editar" @click="edit(  item.uuid  )"> <i class="iconly-Light-Edit mr-10"></i> </span> -->
            <span
              :id="'_show_eliminar' + item.uuid"
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
            >
              <i class="iconly-Light-Delete mr-10" />
            </span>
          </b-row>

          <b-tooltip
            :target="'_show_button_' + item.uuid"
            triggers="hover"
          >
            Ver
          </b-tooltip>

          <b-tooltip
            :target="'_show_editar' + item.uuid"
            triggers="hover"
          >
            Editar
          </b-tooltip>

          <b-tooltip
            :target="'_show_eliminar' + item.uuid"
            triggers="hover"
          >
            Eliminar
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          />
        </template>
      </datatable>
    </div>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Confirmar eliminación
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar esta visita?
          </p>
        </b-col>

        <b-col
          cols="6"
          class="pr-2"
        >
          <b-button
            variant="danger"
            block
            @click="confirmDelete"
          >
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col
          cols="6"
          class="pl-2"
        >
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- create document -->
    <b-modal
      id="modal-add-document-admin"
      size="xl"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Añadir nueva visita
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Seleccione departamento">
              <b-form-select
                v-model="documentCreate.department_uuid"
                :options="Departaments"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="documentCreate.name"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Tipo de Documento">
              <b-form-select
                v-model="documentCreate.dni_type_uuid"
                :options="DniType"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="
              documentCreate.dni_type_uuid == 4 ||
                documentCreate.dni_type_uuid == 3
            "
            cols="12"
          >
            <b-form-group label="RUT">
              <imask-input
                v-imask="dniMask"
                class="form-control"
                :value="documentCreate.dni"
                :unmask="true"
                placeholder="00.000.000-00"
                @complete="onCompleteDni"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="
              documentCreate.dni_type_uuid != 4 &&
                documentCreate.dni_type_uuid != 3
            "
            cols="12"
          >
            <b-form-group label="Documento">
              <b-form-input
                v-model="documentCreate.dni"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Patente">
              <b-form-input
                v-model="documentCreate.patent_number"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Nro Estacionamiento">
              <b-form-input
                v-model="documentCreate.parking_number"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Comentario">
              <b-form-textarea
                v-model="documentCreate.comment"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="submitFile"
            >
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- edit document -->
    <!-- <b-modal
      size="xl"
      id="modal-edit-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-document-admin"
      modal-class="hp-profile-modal-1"
      @hide="modalClose"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo producto</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>

          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input v-model="documentCreate.title" type="text" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Contenido">
              <b-form-input v-model="documentCreate.content" type="text" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="submitFileEdit"
            >
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>

        </b-row>
      </form>
    </b-modal> -->

    <!-- View document detail -->
    <b-modal
      id="modal-detail-document-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-detail-document-admin"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Ver detalle de la visita
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="documentCreate.name"
                type="text"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="RUT">
              <b-form-input
                v-model="documentCreate.dni"
                type="text"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Patente">
              <b-form-input
                v-model="documentCreate.patent_number"
                type="text"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Nro Estacionamiento">
              <b-form-input
                v-model="documentCreate.parking_number"
                type="text"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Comentario">
              <b-form-textarea
                v-model="documentCreate.comment"
                type="text"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-detail-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from 'bootstrap-vue'

import datatable from '@/layouts/components/datatable/Datatable.vue'
import sweet from '@/logic/functions/sweetAlert'
import visits from '@/logic/functions/visits'
import dni from '@/logic/functions/dni'
import departaments from '@/logic/functions/departaments'
import { DateTime } from 'luxon'
import { IMaskComponent, IMaskDirective } from 'vue-imask'
import validate_dni from '@/logic/functions/users/validate_dni'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, // this
    BFormFile,
    BPagination,
    datatable,
    'imask-input': IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      fields: [
        {
          key: 'uuid',
          label: 'Nombre',
          formatter: (value, key, item) => item.name,
        },
        { key: 'dni', label: 'RUT' },
        {
          key: 'department_uuid',
          label: 'Departamento',
          formatter: (value, key, item) => item.department_uuid.nro,
        },
        {
          key: 'created_at',
          label: 'Fecha de visita',
          formatter: (value, key, item) => `${DateTime.fromISO(item.created_at).toFormat(
            'dd/MM/yyyy HH:mm ',
          )}hrs`,
        },
        { key: 'actions', label: 'Acciones' },
      ],
      Actions: [],
      items: [],
      loadNewUsers: [],
      codeText: '',
      codeActive: false,
      codeActiveClass: false,
      flags: {
        formUpload: false,
        fileUpload: false,
      },
      Departaments: [],
      DniType: [],
      createSales: {},
      documentCreate: {
        dni_type_uuid: 3,
      },
      dniIsValid: false,
      dniMask: {
        mask: '00.000.000-{[*]}',
        lazy: false,
      },
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
      departament_filter: '',
    }
  },

  watch: {
    totalRowsPerpage(newValue) {
      console.log(newValue)
    },
  },
  beforeMount() {},
  mounted() {
    this.listDepartaments()
    this.listDniType()
    this.documents()
  },
  methods: {
    clearSelection() {
      this.documentCreate.department_uuid = this.departament_filter
      // this.documentCreate.department_uuid = ''
      // this.departament_filter = this.documentCreate.department_uuid
      this.documents()
    },
    onChangeDepartament(e) {
      // this.departament_filter = this.documentCreate.department_uuid
      this.documentCreate.department_uuid = this.departament_filter
      this.documents()
    },
    onCompleteDni(e) {
      const dni = e.detail._value.replace(/\./g, '')
      if (validate_dni.validaRut(dni)) {
        this.dniIsValid = true
        this.documentCreate.dni = e.detail._value
        e.target.style.border = 'none'
      } else {
        this.dniIsValid = false
        e.target.style.border = '3px solid #FF0000'
      }
    },
    onComplete(e) {
      this.documentCreate.dni = e.detail._value
    },
    show(uuid) {
      this.documentCreate = this.items.find(item => item.uuid == uuid)

      this.$bvModal.show('modal-detail-document-admin')
    },
    edit(uuid) {
      this.documentCreate = {}
      this.documentCreate = this.items.find(item => item.uuid == uuid)

      this.$bvModal.show('modal-edit-document-admin')
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete)
        this.$bvModal.hide('modal-delete-confirmation')
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid
      this.$bvModal.show('modal-delete-confirmation')
    },
    deletes(uuid) {
      const { items } = this
      visits
        .delete(uuid)
        .then(response => {
          const index = items.findIndex(item => item.uuid === uuid)
          if (~index) {
            items.splice(index, 1)
          }
          sweet.ToastMsg('', 'success', 'visita eliminada')
        })
        .catch(error => {})
    },
    async documents(page = 0, limit = 5, departmentUuid = '') {
      await visits
        .list({
          params: {
            department_uuid: this.departament_filter || departmentUuid,
            limit: this.totalRowsPerpage || limit,
            page: this.currentPage || page,
          },
        })
        .then(response => {
          this.items = response.data.data
          this.totalRows = response.data.items
          this.totalRowsPerpage = response.data.limit
        })
        .catch(error => {})
    },
    async submitFile() {
      await visits
        .create({
          comment: this.documentCreate.comment,
          department_uuid: this.documentCreate.department_uuid,
          dni: this.documentCreate.dni,
          dni_type_uuid: this.documentCreate.dni_type_uuid.toString(),
          name: this.documentCreate.name,
          parking_number: this.documentCreate.parking_number,
          patent_number: this.documentCreate.patent_number,
        })
        .then(response => {
          this.documents()
          this.$bvModal.hide('modal-add-document-admin')
          sweet.ToastMsg('', 'success', 'Visita creada')
        })
        .catch(error => {
          this.flags.fileUpload = false
          sweet.ToastMsg('', 'error', 'Visita no creada')
        })
    },
    async listDniType() {
      await dni
        .list()
        .then(response => {
          Object.entries(response.data.data).forEach(entry => {
            const [key, value] = entry
            this.DniType.push({ value: value.uuid, text: value.name })
          })
        })
        .catch(error => {})
    },
    async listDepartaments() {
      await departaments
        .list()
        .then(response => {
          Object.entries(response.data.data).forEach(entry => {
            const [key, value] = entry
            this.Departaments.push({ value: value.uuid, text: value.nro })
          })
        })
        .catch(error => {})
      if (this.Departaments.length) {
        this.documentCreate.department_uuid = this.Departaments[0].value
        this.departament_filter = this.Departaments[0].value
      }
      this.documents()
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1
      this.currentPage = page
      this.documents(page)
    },
    async perPageChanged(perPage) {
      this.totalRowsPerpage = perPage
      this.currentPage = 0
      this.documents(0, perPage)
    },
    maskdni(e) {
      const inputValue = e

      const dni = inputValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{1})/,
        '$1.$2.$3-$4',
      )

      this.documentCreate.dni = dni
    },
  },
}
</script>
